// extracted by mini-css-extract-plugin
export var clientQuotesMobileDev = "C_fS";
export var devBenefitBussinesMobileDev = "C_fN";
export var domainExpertiseMobileDev = "C_fJ";
export var mobileAppDevTechnologies = "C_fF";
export var mobileAppDevTechnologies__wrapper = "C_fG";
export var mobileAppProjLogoSection = "C_fQ";
export var ourKeyPrinciplesMobileDev = "C_fM";
export var serviceMobileTestimonialPost = "C_fL";
export var servicesCardBorderIconMobileDevSection = "C_fC";
export var servicesCardWithTopBorderSectionMobileApp = "C_fD";
export var servicesProcessMobileDevSection = "C_fK";
export var successStoriesMobileSection = "C_fR";
export var weBuildProjectsDifferentSection = "C_fH";
export var whyChooseServicesMobile = "C_fP";