// extracted by mini-css-extract-plugin
export var mobileAppCardTechStack = "dx_CC";
export var mobileAppCardTechStack__card = "dx_CD";
export var mobileAppCardTechStack__description = "dx_CG";
export var mobileAppCardTechStack__iconsMobileDev = "dx_CH";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechJS = "dx_CM";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechKotlin = "dx_CK";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechReact = "dx_CL";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechSwift = "dx_CJ";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechTS = "dx_CN";
export var mobileAppCardTechStack__title = "dx_CF";