import { Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import CompanyWhyHireBlock from '~/components/blocks/CompanyWhyHireBlock'
import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import Testimonial from '~/components/blocks/TestimonialPost'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import SuccessStoriesSliderSection from '~/components/sections/SuccessStoriesSliderSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/mobile/Anchors'
import MobileDevBenefits from '~/mock/services/mobile/MobileDevBenefits'
import MobileDevBuildProjects from '~/mock/services/mobile/MobileDevBuildProjects'
import MobileDevExpertise from '~/mock/services/mobile/MobileDevExpertise'
import MobileDevFAQ from '~/mock/services/mobile/MobileDevFAQ'
import MobileDevKeyPrinciples from '~/mock/services/mobile/MobileDevKeyPrinciples'
import MobileDevPlatforms from '~/mock/services/mobile/MobileDevPlatforms'
import MobileDevProcess from '~/mock/services/mobile/MobileDevProcess'
import MobileDevServices from '~/mock/services/mobile/MobileDevServices'
import MobileDevTechnologies from '~/mock/services/mobile/MobileDevTechnologies'
import MobileDevWhyChoose from '~/mock/services/mobile/MobileDevWhyChoose'
import { fileToImageLikeData } from '~/utils'
import CompanyProjectsLogoSection from '~/views/Homepage/components/CompanyProjectsLogoSection'
import ServicesMobileAppStackCardSection from '~/views/Services/components/ServicesMobileAppStackCardSection'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesTechStackSectionCrossPlatform from '~/views/Services/components/ServicesTechStackSectionCrossPlatform'
import useMobileAppStaticQuery from '~/views/Services/MobileApp/useMobileAppStaticQuery'

import * as containerStyles from './MobileApp.module.scss'

const ServicesMobileApp = () => {
  const query = useMobileAppStaticQuery()
  const clientImpact = getImage(fileToImageLikeData(query.clientImpact))
  const quoteCodicaEugenePhoto = getImage(
    fileToImageLikeData(query.quoteCodicaEugenePhoto),
  )

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="Mobile App"
        title="Development Services"
        description="Have a great idea for a revolutionary mobile app? We know how to build an app that your users will love."
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="Mobile App Development Services"
        breadcrumbCurrentPath="/services/mobile-app-development/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.servicesCardBorderIconMobileDevSection}
      >
        <HeadSection
          position="center"
          title="Our best mobile app development services"
          description={[
            `Growing experience and professionalism for almost 8 years, our team has succeeded in developing over 50 high-quality web products and solutions. This is our company's mission - to develop and create software that engages users and increases the efficiency of your business. We provide quality `,
            <Link
              to="/mobile-app-development-e-book/"
              key="mobileAppDevLinkKey"
            >
              mobile app development services
            </Link>,
            ', and you receive quality results.',
          ]}
          titleLarge
          descriptionLarge
        />
        <Service3DIconCard dataContent={MobileDevServices} />
      </section>

      <section
        id="platforms"
        className={containerStyles.servicesCardWithTopBorderSectionMobileApp}
      >
        <HeadSection
          position="center"
          title="Mobile application development services for different platforms"
          description="At Codica, our mobile application development experts assist clients in realizing their business ideas by providing them with high-quality customized mobile solutions that attract users and increase sales."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={MobileDevPlatforms} />
      </section>

      <CallToAction
        title="Want to turn your app idea into reality today?"
        subtitle="Get started with our mobile app development experts."
        classNameForGA="servicesCTA_1"
        linkName="Contact us"
        link="/contacts/"
      />

      <section
        id="tech-stack"
        className={containerStyles.mobileAppDevTechnologies}
      >
        <HeadSection
          position="center"
          title="Mobile app development technologies we use"
          description="You can rely on Codica as an experienced mobile app development team. Over the years, we've curated a list of development tools that help us build high-quality, engaging apps and help businesses scale."
          descriptionLarge
        />
        <ServicesTechStackSectionCrossPlatform
          isCrossPlatformMobileAppTech
          isAndroidTech
          isIosTech
        />
        <div className={containerStyles.mobileAppDevTechnologies__wrapper}>
          <ServicesMobileAppStackCardSection
            dataContent={MobileDevTechnologies}
          />
        </div>
      </section>

      <section
        id="business-type"
        className={containerStyles.weBuildProjectsDifferentSection}
      >
        <HeadSection
          position="center"
          title="We build projects that cover the needs of different audiences"
          description="At Codica, we can satisfy various business needs of our clients with our mobile app development services - from startup creation to redesigning existing solutions."
          descriptionLarge
        />
        <ServicesIndustriesCard isColTwo dataContent={MobileDevBuildProjects} />
      </section>

      <section
        id="industries"
        className={containerStyles.domainExpertiseMobileDev}
      >
        <HeadSection
          position="center"
          title="Development of applications for business in different industries"
          description="Providing mobile application development services for specific industries has enabled our team to maintain in-house expertise, which we share with our customers. These are industries we have significant expertise:"
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={MobileDevExpertise} />
      </section>

      <section
        id="process"
        className={containerStyles.servicesProcessMobileDevSection}
      >
        <HeadSection
          position="center"
          title="Our end-to-end mobile app development process"
          description="The Codica team has more than 7 years of experience in building custom software solutions. Our experts will do their best and make your journey from idea to finished app easy. For the entire time of our work, we have developed a universal algorithm for working on a successful project."
          descriptionLarge
        />
        <ServicesProcess dataContent={MobileDevProcess} />
      </section>

      <div className={containerStyles.serviceMobileTestimonialPost}>
        <Testimonial
          image={quoteCodicaEugenePhoto}
          name="Eugene"
          position="Tech Lead & React expert at Codica"
          quote={[
            {
              text: '“The process of developing a mobile app seems complicated at first glance. Yes, you will have to make many important decisions as a client. But you can rely on the Codica team. Our team of specialists will be your assistant in solving various non-standard tasks quickly and efficiently.',
            },
            {
              text: 'Speed, usability, reliability, excellent interface design - this is all about the apps made by our team. In addition, we not just develop mobile apps but also provide high-quality support for them.”',
            },
          ]}
        />
      </div>

      <section
        id="key-principles"
        className={containerStyles.ourKeyPrinciplesMobileDev}
      >
        <HeadSection
          position="center"
          title="Our key principles of mobile development"
          titleLarge
        />
        <ServicesIconSmallCard isColSm dataContent={MobileDevKeyPrinciples} />
        <SubtractBlock />
      </section>

      <section
        id="benefits"
        className={containerStyles.devBenefitBussinesMobileDev}
      >
        <HeadSection
          position="center"
          title="How will mobile application development benefit your business?"
          description={[
            'Custom mobile apps can be fully personalized to suit your business needs. Our application development experts have extensive experience working with different requirements and domains. Based on our rich background, we will be able to help you choose the best option to implement your business idea.',
            <span className="mb-2 d-block" key="keyDescription" />,
            'So, what benefits do you obtain with the custom mobile app development approach?',
          ]}
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={MobileDevBenefits} />
      </section>

      <CallToAction
        title="Searching for an expert mobile app development team?"
        subtitle="Let's build your mobile app today."
        classNameForGA="servicesCTA_2"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section className={containerStyles.whyChooseServicesMobile}>
        <HeadSection position="center" title="Why choose Codica?" />
        <CompanyWhyHireBlock dataContent={MobileDevWhyChoose} />
      </section>

      <LazyHydrate whenVisible>
        <section className={containerStyles.mobileAppProjLogoSection}>
          <CompanyProjectsLogoSection isCenter />
        </section>
      </LazyHydrate>

      <section
        id="cases"
        className={containerStyles.successStoriesMobileSection}
      >
        <HeadSection
          position="center"
          title="Cases"
          description="Our clients like partnering with us for their leading digital projects."
        />
        <SuccessStoriesSliderSection btnName="View all our works" />
      </section>

      <section className={containerStyles.clientQuotesMobileDev}>
        <HeadSection
          position="center"
          title="Our clients say about Codica"
          description="Codica’s focus is always on our client's experience working with us. We love hearing their feedback and are grateful that clients share their opinions!"
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Richard Gunter"
            location="Israel"
            position="Founder of Impact Personal Training"
            image={clientImpact}
            quote={[
              {
                text: '“Coming from a gym environment, I had no idea how to build, create, and develop an app. Everything I needed to do was explained to me in layman’s terms. I was always up to date with where they were at on the project itself, which I found really helpful.”',
              },
            ]}
            link="fitness-progressive-web-application"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s turn your idea into a successful product!"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our expert mobile app developers will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={MobileDevFAQ} />
      </section>
    </MainLayout>
  )
}

export default ServicesMobileApp
